import { render, staticRenderFns } from "./ReceptionPendingView.vue?vue&type=template&id=dee8e0fa&scoped=true"
import script from "./ReceptionPendingView.vue?vue&type=script&lang=js"
export * from "./ReceptionPendingView.vue?vue&type=script&lang=js"
import style0 from "./ReceptionPendingView.vue?vue&type=style&index=0&id=dee8e0fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee8e0fa",
  null
  
)

export default component.exports